body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-dropdown {
  z-index: 1;
}

.ant-popover .ant-popover-inner {
  padding: 14px;
}

.ant-popconfirm .ant-popconfirm-message {
  margin-bottom: 15px;
}

.ant-btn-sm {
  padding: 0px 15px;
} 
.ant-input-outlined:focus {
  box-shadow: none;
}
